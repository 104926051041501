import React, { useState } from 'react';
import axios from 'axios';

const OnlineCampRegistration = ({ formData, handleChange }) => {

    return (
        <div className="max-w-xl mx-auto bg-gray-700 p-8 rounded-3xl shadow-3xl">
            <h2 className="comic-sans-medium text-yellow-100 text-2xl font-bold mb-4">Online Summer Camp Registration</h2>
            <form>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="mb-4">
                        <label className="comic-sans-small block text-yellow-100">Your Name</label>
                        <input
                            type="text"
                            name="parentName"
                            value={formData.parentName}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded-lg"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="comic-sans-small block text-yellow-100">Your Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded-lg"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="comic-sans-small block text-yellow-100">Your Child's Name</label>
                        <input
                            type="text"
                            name="childName"
                            value={formData.childName}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded-lg"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="comic-sans-small block text-yellow-100">Your Child's Age</label>
                        <input
                            type="number"
                            name="childAge"
                            value={formData.childAge}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded-lg"
                            required
                        />
                    </div>
                </div>
                {/* <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600"
    >
        Register
    </button> */}
            </form>

        </div>
    );
};

export default OnlineCampRegistration;