import { X } from "react-feather";
import React from "react";
import TobBar from "../../../components/TobBar";

export default function Modal({ open, onClose, children }) {
  return (
    // backdrop
    <><TobBar />
    <div
      className={`
          fixed pt-20 md:pt-20 pb-20 inset-0 md:flex md:justify-center md:items-center transition-colors
          ${open ? "visible bg-black/20" : "invisible"}
        `}
    >
      {/* modal */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`
            mt-20 md:mt-20 w-full md:w-[40%] bg-white rounded-xl shadow  transition-all
            ${open ? "scale-90 opacity-100" : "scale-125 opacity-0"}
          `}
      >
        {/* <button
          onClick={onClose}
          className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
        >
          <X />
        </button> */}
        {children}
      </div>
    </div>
    </>
  );
}
