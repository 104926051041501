import React from "react";
import video from "../assets/videos/helpingVideo.mp4";
import { useNavigate } from "react-router-dom";

const KidsVideo = () => {
    const navigate = useNavigate();

    const handleStartCourse = () => {
        navigate("/books");
    };
    return (
        <>
            <div className="flex flex-col md:flex-row justify-between items-center w-full p-3 md:px-20">
                <div className="w-full md:w-1/2 p-2">
                    <iframe className="w-full rounded-3xl shadow-2xl" height="300" src="https://www.youtube.com/embed/jVuIcHUGv2I?si=NRwAsbgUuWwYx_mE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className="flex flex-col justify-center items-center md:justify-start md:items-start col-span-12 md:col-span-6 place-self-center mt-10 md:ml-20">
                    <p className="comic-sans-medium text-center md:text-start md:text-5xl text-gray-800 whitespace-pre-line md:leading-relaxed">
                        You can start <br /> a business at <br /> a younger age! <br />Make your kids <br />
                        business-oriented
                    </p>
                    <button
                        className=" mt-8 btn outline-button text-white py-1 px-6 border rounded-2xl"
                        onClick={handleStartCourse}
                    >
                        Learn More
                    </button>
                </div>
            </div>

            {/* <div className="flex justify-center items-center w-full">
                <video controls className=" h-[85vh]">
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div> */}
        </>
    );
};

export default KidsVideo;