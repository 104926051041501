import React from "react";
import logo from "../assets/images/logoAndText.png";

function Footer() {
  return (
    <footer className="bg-[#7D9BE2] pb-10">
      <div className="mx-auto w-full max-w-screen-xl mb-0 p-2 lg:py-6 ">
        <div className="md:flex md:justify-between">
          <div className=" md:mb-0">
            <a href="" className="flex items-center">
              <img src={logo} className="h-[120px] w-[120px] cursor-pointer rounded-full" alt="" />
            </a>
            <div>
              <ul className="text-black font-medium ">
                <li>
                  <a href="/story" className="hover:underline bubblegum-sans-link text-[#F7F9F3]">
                    Our Story
                  </a>
                </li>
                <li>
                  <a href="/blogs" className="hover:underline bubblegum-sans-link text-[#F7F9F3]">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="/courses" className="hover:underline bubblegum-sans-link text-[#F7F9F3]">
                    Products
                  </a>
                </li>
                <li>
                  <a href="/programs" className="hover:underline bubblegum-sans-link text-[#F7F9F3]">
                    Programs
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="grid grid-cols-2  sm:gap-2 sm:grid-cols-3">
            <div>
              <h2 className="bubblegum-sans-subheader mb-4 text-sm font-semibold text-[#F7F9F3] uppercase">
                Resources
              </h2>
              <ul className="text-black font-medium">
                <li className="mb-2">
                  <a href="#" className="hover:underline bubblegum-sans-link text-[#F7F9F3]">
                    Teaser #1
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center space-y-3 mr-10">
              <h2 className="bubblegum-sans-subheader mb-2 text-sm font-semibold text-[#F7F9F3] uppercase">
                Follow us
              </h2>
              <ul className="flex flex-col justify-center items-center text-gray-500 text-center font-medium">
                {/* <li className="mb-2">
                  <a href="" className="text-gray-500 hover:text-gray-900">
                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 8 19">
                      <path fillRule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Facebook page</span>
                  </a>
                </li> */}
                <li>
                  <a href="https://www.tiktok.com/@mentor_able?_t=8mkpkkyEI90&_r=1" className="text-gray-500 hover:text-gray-900">
                    <span className="[&>svg]:h-8 [&>svg]:w-8">
                      <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 500 600">
                        <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                      </svg>
                      <span className="sr-only">TikTok page</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="bubblegum-sans-subheader mb-2 text-sm font-semibold text-[#F7F9F3] uppercase">
                Legal
              </h2>
              <ul className="text-gray-500 font-medium">
                <li className="mb-2">
                  <a href="" className="hover:underline bubblegum-sans-link text-[#F7F9F3]">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="" className="hover:underline bubblegum-sans-link text-[#F7F9F3]">
                    Terms &amp; Conditions
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-2 border-gray-200 sm:mx-auto" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-black sm:text-center">
            Copyright© 2024{" "}
            <a href="" className="hover:underline bubblegum-sans-link">
              Mentorable™
            </a>
            . All Rights Reserved.
          </span>
          <div className="flex mt-2 sm:justify-center sm:mt-0">
            {/* <a href="" className="text-gray-500 hover:text-gray-900">
              <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 8 19">
                <path fillRule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clipRule="evenodd" />
              </svg>
              <span className="sr-only">Facebook page</span>
            </a> */}
            <a href="https://www.tiktok.com/@mentor_able?_t=8mkpkkyEI90&_r=1" className="text-gray-500 hover:text-gray-900">
              <span className="[&>svg]:h-5 [&>svg]:w-5">
                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 500 600">
                  <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
    </footer>

  );
}

export default Footer;
