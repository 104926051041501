import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import BlogsDetail from "./components/Details/BlogsDetail";
import CoursesDetail from "./components/Details/CoursesDetail";
import Home from "./components/Home";
import ProgramsDetail from "./components/Details/ProgramsDetail";
import Book from "./components/Book";
import Chapter from "./components/Details/Chapter";
import { UserAuthContextProvider } from "./components/Auth/UserAuthContext";
import { Provider } from "react-redux";
import store from "./redux/store";
import Checkout from "./components/Payment/Checkout";
import LearnBook from "./components/LearnBook/LearnBook";

import Navbar from "./components/Navbar";

import FullBlog from "./components/Details/FullBlog";
import SummerCampDetail from "./components/Details/SummerCampDetail";

import SignInDialog from "./apps/account/components/SignInDialog";
import PrivateRoutes from "./apps/account/components/PrivateRoute";
import Login from "./apps/account/components/Login";
import Blog2 from "./components/Details/Blog2";
import Blog3 from "./components/Details/Blog3";
import Blog4 from "./components/Details/Blog4";
import OurStory from "./components/OurStory";
import PurchaseButton from "./components/Payment/PurchaseButton";
import Footer from "./components/Footer";
import BookSend from "./components/BookSend";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { apiResponse: "" };
  }

  callAPI() {
    // fetch("https://mentor-able/api/")
    // .then(res => res.text())
    // // console.log("RESPONSE", res)
    // .then(res => this.setState({ apiResponse: res }))
    // .catch(err => err);
    // console.log("RESPONSE", res)
  }

  componentDidMount() {
    // this.callAPI();
  }

  render() {
    return (
      // <div >
      //           <header >
      //               {/* <img src={logo} className="App-logo" alt="logo" /> */}
      //               <h1 >Welcome to React</h1>
      //           </header>
      //           <p >{this.state.apiResponse}</p>
      //       </div>
    <Provider store={store}>
      <UserAuthContextProvider>
        <div>
          <BrowserRouter>
            <Navbar />
            {/* <PurchaseButton /> */}
            {/* <button onClick={this.handlePaymentClick}>Make Payment</button> */}
            {/* <p className="text-black">{this.state.apiResponse}</p> */}
            {/* <p>HELLO</p> */}
            <div className="">
            <Routes>
              <Route exact path="/" Component={Home} />
              <Route 
              exact 
              path="/courses" 
              element={<PrivateRoutes><OurStory /></PrivateRoutes>}
              // Component={OurStory} 
              />
              <Route exact path="/blogs" Component={BlogsDetail} />
              <Route exact path="/programs" Component={ProgramsDetail} />
              <Route
                exact
                path="/courses/book/:bookId"
                element={
                  <PrivateRoutes>
                    <Book />
                  </PrivateRoutes>
                }
              />
              {/* <Route element={<PrivateRoutes />}>
                <Route exact path="/courses" Component={CoursesDetail} />
              </Route> */}
              <Route
              path="/books"
              element={
                <PrivateRoutes>
                  <CoursesDetail />
                </PrivateRoutes>
              }
              >

              </Route>
              {/* <Route
                path="/courses"
                element={
                  <PrivateRoutes>
                    <CoursesDetail />
                  </PrivateRoutes>
                }
              /> */}
              <Route
                path="/camp"
                element={
                  <PrivateRoutes>
                    <SummerCampDetail />
                  </PrivateRoutes>
                }
              />
              <Route
                exact
                path="/courses/book/:bookId/chapter/:chapternumber"
                Component={Chapter}
              />

              {/* <Route
              path="http://localhost:9000/chapaAPI/api/pay"
              Component={Checkout}
               /> */}

              <Route 
              // exact 
              path="/book/:bookId/checkout" 
              element={
                <PrivateRoutes>
                  {Checkout}
                </PrivateRoutes>
              }
              Component={Checkout} 
              />

              <Route exact path="blogs/fullBlog" Component={FullBlog} />
              <Route exact path="blogs/blog2" Component={Blog2} />
              <Route exact path="blogs/blog3" Component={Blog3} />
              <Route exact path="blogs/blog4" Component={Blog4} />
              <Route exact path="blogs/fullBlog/blog2" Component={Blog2} />
              <Route exact path="blogs/fullBlog/blog3" Component={Blog3} />
              <Route exact path="blogs/fullBlog/blog4" Component={Blog4} />
              <Route exact path="blogs/blog2/fullBlog" Component={FullBlog} />
              <Route exact path="blogs/blog2/blog3" Component={Blog3} />
              <Route exact path="blogs/blog2/blog4" Component={Blog4} />
              <Route exact path="blogs/blog3/fullBlog" Component={FullBlog} />
              <Route exact path="blogs/blog3/blog2" Component={Blog2} />
              <Route exact path="blogs/blog3/blog4" Component={Blog4} />
              <Route exact path="blogs/blog4/fullBlog" Component={FullBlog} />
              <Route exact path="blogs/blog4/blog2" Component={Blog2} />
              <Route exact path="blogs/blog4/blog3" Component={Blog3} />
              <Route exact path="/login" Component={Login} />

              <Route
                // exact
                path="/book/:bookId/checkout/learn"
                element={
                  <PrivateRoutes>
                    <BookSend />
                  </PrivateRoutes> 
                  // <PrivateRoutes>
                    // <LearnBook />
                  // </PrivateRoutes>
                }
                // Component={LearnBook}
              />
              {/* <Route exact path="/camp" Component={SummerCampDetail} /> */}
            </Routes>
            </div>
            <SignInDialog />
            {/* <Footer /> */}
          </BrowserRouter>
        </div>
      </UserAuthContextProvider>
    </Provider>
  );}
};

export default App;
