import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import camp from '../assets/images/camp.jpg';
import OnlineCampRegistration from '../apps/account/components/OnlineCampRegistration';
import { db } from "../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

const OnlineCamp = () => {
    const [isOpen, setIsOpen] = useState(false);
    // const [parentName, setParentName] = useState("");
    // const [email, setEmail] = useState("");
    // const [childName, setChildName] = useState("");
    // const [childAge, setChildAge] = useState(0);
    const [formData, setFormData] = useState({
        parentName: '',
        email: '',
        childName: '',
        childAge: '',
    });
    const [isPaid, setIsPaid] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // function handleInput(event) {
    //     setParentName(event.target.value);
    //     setEmail(event.target.value);
    //     setChildName(event.target.value);
    //     setChildAge(event.target.value);
    // }

    // function isEmailValid(event) {

    // }

    const handleRegister = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            console.log("Sending registration data to server...");
            console.log("Registration Data:", formData);
            const response = await fetch('https://mentor-able.com/camp/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                const responseText = await response.text();
                console.error("handleButtonClick: Network response was not ok", responseText);
                throw new Error(`Network response was not ok: ${responseText}`);
            }

            const data = await response.json();
            console.log("Server responded with data:", data);

            window.location.href = data.checkout_url;

            // await addDoc(collection(db, "campRegistrations"), {
            //     email: formData.email,
            //     parentName: formData.parentName,
            //     childName: formData.childName,
            //     childAge: formData.childAge,
            //     createdAt: serverTimestamp()
            // });
            // console.log("Registration data successfully added to Firestore.");
            // alert(`Thank you for Registering for our Online Camp! We will soon email you with the details`);
            // setFormData({
            //     parentName: '',
            //     email: '',
            //     childName: '',
            //     childAge: ''
            // });
            // console.log("handlePaymentClick: Redirecting to", data.checkout_url);
            // console.log("RETURN URL", data.return_url);
            // console.log("THE DATA!", data);
        }
        catch (error) {
            console.error('handlePaymentClick: Error occurred during payment process:', error);
            alert('There was an error during the registration process. Please try again.');
        } finally {
            setLoading(false);
            // setIsPaid(true);
            // console.log("Is Paid: ", isPaid);
        }
    };

    const testFirestoreWrite = async () => {
        try {
            console.log("Testing Firestore write...");
            await addDoc(collection(db, "campRegistrations"), {
                email: "test@example.com",
                parentName: "Test Parent",
                childName: "Test Child",
                childAge: 10,
                createdAt: serverTimestamp()
            });
            console.log("Firestore write successful.");
        } catch (error) {
            console.error("Firestore write failed:", error);
        }
    };


    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center min-h-screen m-4 md:p-3 shadow-2xl rounded-3xl relative overflow-hidden">
                <div className="area w-full top-0 left-0 h-full">
                    <ul className="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>

                <div className="flex flex-col md:flex-row items-center w-full max-w-full z-10">
                    <img
                        src={camp}
                        id="clickable-image"
                        onClick={openModal}
                        width={300}
                        height={300}
                        className="flex-shrink-0 md:w-100 md:h-100 mt-8 md:m-5 border-2 border-black shadow-lg cursor-pointer rounded-lg"
                    />
                    {isOpen && (
                        <div className="modal" onClick={closeModal}>
                            <span className="close" onClick={closeModal}>&times;</span>
                            <img className="modal-content" src={camp} alt="Camp Poster" />
                        </div>
                    )}
                    <div className="bg-yellow-100 flex flex-col justify-between p-4 md:p-6 rounded-2xl shadow-3xl flex-grow md:ml-5 text-center relative z-10 mt-6 md:mt-0 w-full max-w-full">
                        <div>
                            <h1 onClick={testFirestoreWrite} className="comic-sans-medium text-xl md:text-4xl font-bold text-black mb-2 md:mb-4">Register Your Child for Online Camp! <br /> (Starts in July 11)</h1>
                            {/* <p className="comic-sans-small text-md md:text-lg text-gray-700 mb-4 mt-4 md:mt-10">
                    Join our exciting Online Summer Camp and let your kids have an amazing learning experience from home!
                </p> */}
                        </div>
                        <OnlineCampRegistration formData={formData} handleChange={handleChange} />
                        <Link onClick={handleRegister}>
                            <a
                                href="#"
                                className="animate-bounce focus:animate-none hover:animate-none inline-flex text-sm md:text-md font-medium bg-indigo-900 mt-5 md:mt-3 px-4 py-2 rounded-lg tracking-wide text-white"
                            >
                                <span className="ml-2">Register Now</span>
                            </a>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OnlineCamp;

{/* <img
                    src={camp}
                    width={300}
                    height={300}
                    className="flex-shrink-0 md:w-100 md:h-100 mt-8 md:m-5 border-2 border-black shadow-lg cursor-pointer rounded-lg"
                /> */}