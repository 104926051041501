import React, { Component } from "react";

class PurchaseButton extends Component {
    constructor(props) {
        super(props);
        this.state = { apiResponse: "" };
    }

    handlePaymentClick = async () => {
        try {
            const response = await fetch('http://localhost:9000/chapaAPI/api/pay', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({})
            });
            
            if(!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            window.location.href = data.checkout_url;
            console.log(data);
        }
        catch (error) {
            console.error('Error:', error);
          }
    };

    render() {
        return (
            <a href="https://checkout.chapa.co/checkout/payment/TRiegYS6AOaF2HLGAMML3K6dWv60sNiD6fnHFplgg4spe">
            <button
                // onClick={this.handlePaymentClick}
                className="btn outline-button hover:bg-middlesection text-black font-semibold hover:text-black py-2 px-4 rounded"
              >
                Purchase Course
              </button>
              </a>
        )
    }
}

export default PurchaseButton;