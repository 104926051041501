import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { Typography, Card, CardBody } from "@material-tailwind/react";
import book2 from "../../assets/images/climateCover.png";
import book3 from "../../assets/images/financialCover.jpg";
import book4 from "../../assets/images/socialCover.jpg";

import Header from "../Header";
import middlesection from "../../assets/images/CoverNew.jpg";
import { NavLink } from "react-router-dom";
import { query, collection, getDocs, where } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setUser, listUser, clearUser } from "../../redux/slices/userSlice";
// import { Card}
import { motion } from "framer-motion";
import TobBar from "../TobBar";

function CoursesDetail() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const firebaseUser = useSelector((state) => state.userData.user);
  console.log("firebase user in coursedetail", firebaseUser);
  const [uid, setUID] = useState("");
  const [user, loading, error] = useAuthState(auth);
  let navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      console.log("DOCS", doc);
      const data = doc.docs[0].data();
      console.log("DATA", data);
      dispatch(setUser(data));
      setName(data.firstname);
      console.log("NAME:", data.firstname);
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };

  const handleLogOut = () => {
    auth.signOut();
    dispatch(clearUser());
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUID(user.uid);
        console.log("uid", uid);
      } else {
        console.log("user is logged out");
      }
    });
    fetchUserName();
  }, [user]);

  // useEffect(() => {
  //   dispatch(clearUser());
  // }, []);

  return (
    <div className="md:flex flex-col">
      <TobBar />
      <div className="flex justify-center items-center p-5 mt-20 md:mt-20">
        <h1 className="comic-sans-medium text-yellow-100 text-center p-5 bg-black rounded-full mt-10 md:mt-5">Welcome {name}!</h1>
      </div>

      <div className="flex items-center flex-col md:flex items-center flex-row border-t-4 border-black w-full h-auto">
        <div className="md:flex justify-around p-5 m-2 w-full md:w-5/6 max-w-6xl">
        <div className="book-image">
              <img src={middlesection} alt="Book Cover" className="book-cover mt-10 flex justify-center items-center" />
            </div>
          <div className="md:p-4 m-2 flex flex-col space-y-5 w-full md:w-1/2 justify-around">
            <h1 className="comic-sans-medium text-center md:text-leading md:text-5xl text-yellow-100 whitespace-pre-line md:leading-relaxed">The Book That Started It All</h1>
            <p className="comic-sans-small text-center md:text-leading text-xl text-yellow-100">
            Unlock the exciting world of entrepreneurship for your child with "Business for Kids"! This engaging and interactive eBook is designed to introduce young minds to the basics of business and entrepreneurship. Tailored for children aged 7-14, it simplifies complex concepts into fun and understandable lessons.
            </p>
            <div className="flex justify-center items-center">
              <NavLink to={`/courses/book/${1}`}>
                <button className="btn outline-button flex justify-center items-center md:text-leading text-black font-semibold hover:text-black py-2 px-2 hover:border-black rounded">
                  Learn more
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="md:flex items-center flex-col w-full">
        <TabsDefault />
      </div>
    </div>
  );
}

export default CoursesDetail;

export function TabsDefault() {
  const data = [
    {
      label: "AVAILABLE NOW",
      value: "availableNow",
      booklist: [
        {
          src: middlesection,
          id: 1,
          des: "Become a mini-mogul! This book shows you how to turn your ideas into cash.",
          age: "7-14 years",
          // page: "64 pages",
          incluede: true,
        },
        // {
        //   src: book8,
        //   id: 2,
        //   des: "some description about book",
        //   age: "6-12 years",
        //   page: "64 pages",
        //   incluede: true,
        // },
        // {
        //   src: book3,
        //   id: 3,
        //   des: "some description about book",
        //   age: "6-12 years",
        //   page: "64 pages",
        //   incluede: true,
        // },
        // {
        //   src: book5,
        //   id: 4,
        //   des: "some description about book",
        //   age: "6-12 years",
        //   page: "64 pages",
        //   incluede: true,
        // },
        // {
        //   src: book6,
        //   id: 5,
        //   des: "some description about book",
        //   age: "6-12 years",
        //   page: "64 pages",
        //   incluede: true,
        // },
        // {
        //   src: book3,
        //   id: 6,
        //   des: "some description about book",
        //   age: "6-12 years",
        //   page: "64 pages",
        //   incluede: true,
        // },
        // {
        //   src: book3,
        //   id: 7,
        //   des: "some description about book",
        //   age: "6-12 years",
        //   page: "64 pages",
        //   incluede: true,
        // },
      ],
    },
    {
      label: "UPCOMING",
      value: "upcoming",
      booklist: [
        {
          src: book2,
          id: 8,
          des: "Explore how we can be kind to our planet and have fun while doing it!",
          age: "7-14 years",
          // page: "64 pages",
          incluede: true,
        },
        {
          src: book3,
          id: 9,
          des: "Turn your piggy bank into a pro and watch your savings grow!",
          age: "7-14 years",
          // page: "64 pages",
          incluede: true,
        },
        {
          src: book4,
          id: 10,
          des: "Make friends, build teamwork, and be a social superstar with this awesome guide!",
          age: "7-14 years",
          // page: "64 pages",
          incluede: true,
        },
      ],
    },
    // {
    //   label: "TEEN",
    //   value: "teen",
    //   booklist: [
    //     {
    //       src: book7,
    //       id: 11,
    //       des: "some description about book",
    //       age: "6-12 years",
    //       page: "64 pages",
    //       incluede: true,
    //     },
    //     {
    //       src: book8,
    //       id: 12,
    //       des: "some description about book",
    //       age: "6-12 years",
    //       page: "64 pages",
    //       incluede: true,
    //     },
    //   ],
    // },
    // {
    //   label: "KIDS",
    //   value: "kids",
    //   booklist: [
    //     {
    //       src: book7,
    //       id: 13,
    //       des: "some description about book",
    //       age: "6-12 years",
    //       page: "64 pages",
    //       incluede: true,
    //     },
    //   ],
    // },
  ];

  return (
    <Tabs
      className="md:w-[75%] border-t-2 border-black mt-4 flex flex-col h-full overflow-y-auto"
      TabInd
      value="availableNow">
      {/* {data.map((item, index) => {
    return <BookList item={item} key={index} />;
  })} */}
      <TabsHeader className="p-4">
        {data.map(({ label, value }) => (
          <Tab key={value} value={value}>
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody className="flex-grow">
        {data.map(({ value, booklist }) => (
          <TabPanel key={value} value={value} className="flex flex-wrap justify-center">
            {booklist?.map((item, index) => (
              // <div
              //   key={index}
              //   className="flex flex-row w-full mt-10 grid grid-cols-1 gap-10 sm:grid-cols-1 md:grid-cols-3"
              // >
 <div key={index} 
 className="md:w-[25%] w-full md:h-[25%] h-auto flex flex-col m-2 p-2 items-center hover:scale-110">
        {/* <img
          // href="/blogs"
          className="w-[75%] h-[75%] m-6 md:m-4 object-cover border-2 border-black shadow-lg cursor-pointer hover:scale-110 rounded-lg "
          src={item.src}
          alt="lesson image"
        />  */}
        {/* <p>{data.date.toISOString()}</p> */}
        {/* // <p className="bubblegum-sans-subheader p-2 text-black text-center w-[75%] font-paragraph">
        //   {item.des}{" "}
        // </p> */}
      {/* </div> */}
                <img src={item.src} alt="imageindex" />
                <p className="comic-sans-small text-yellow-100 text-center font-bold text-xl">{item.des}</p>
                <div className="flex p-4">
                  <p className="roboto-regular text-yellow-100 text-sm">{item.age}&nbsp;</p>
                  <p className="roboto-regular text-yellow-100 text-sm">{item.page}&nbsp;</p>
                  <p className="roboto-regular text-yellow-100 text-sm">{item.incluede ? "" : ""}</p>
                </div>
                {value === "availableNow" ? (
                  <NavLink to={`/courses/book/${item.id}`}>
                  <button className="btn outline-button hover:bg-middlesection text-black font-semibold hover:text-black py-2 px-2 hover:border-black rounded">
                    Learn More
                  </button>
                </NavLink>
                ) : (
                  <NavLink to={`/courses/book/${item.id}`}>
                  <button className="btn outline-button hover:bg-middlesection text-black font-semibold hover:text-black py-2 px-2 hover:border-black rounded" disabled>
                    Coming Soon!
                  </button>
                </NavLink>
                )}
                
              </div>
            ))}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>


  );
}
