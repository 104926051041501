import React from "react";

const TobBar = () => {
  return (
    <div className="flex justify-end items-center px-4 pt-5 z-1000">
      {/* <a href="https://www.tiktok.com/@mentor_able?_t=8mkpkkyEI90&_r=1" className="text-black hover:text-gray-900">
              <span className="[&>svg]:h-5 [&>svg]:w-5">
                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 500 600">
                  <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                </svg>
              </span>
            </a> */}
      {/* <div className="flex mt-2 sm:justify-center sm:mt-0">
            <a
              href=""
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
                viewBox="0 0 8 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span className="sr-only">Facebook page</span>
            </a>
            <a
              href=""
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <span class="[&>svg]:h-5 [&>svg]:w-5">
                <svg
                  className="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  viewBox="0 0 500 600">
                  <path
                    d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                </svg>
              </span>
            </a> */}
      {/* <div className="flex items-center">
            <h1 className="text-xl font-bold text-gray">WEEKAWAY</h1>
          </div> */}
      {/* <div className="flex flex-wrap">
        <div className="hidden md:flex items-center px-6">
          <p className="text-sm font-bold text-transparent">9AM-5AM</p>
        </div>
        <div className="md:flex items-center px-6">
          <p className="text-sm text-transparent font-bold">+251919191919</p>
        </div> */}
        {/* <button>Get Us</button> */}
        {/* <div className="flex flex-row">
          <a href="#" className="text-transparent no-underline hover:underline mr-2">አማርኛ</a>
          <p className="text-transparent">|</p>
          <a className="text-transparent no-underline hover:underline ml-2">English</a>
        </div>
      </div> */}
    </div>

  );
};

export default TobBar;
