import React from "react";
import video from "../assets/videos/full.mp4";
import { useNavigate } from "react-router-dom";
import TobBar from "./TobBar";

const FilagotVideo = () => {
    return (
        <>
            <div className="flex flex-col md:flex-row justify-center items-center w-full h-auto mt-20 md:mt-0 p-3 md:p-20">
                <div className="w-full h-full  p-2">
                    <iframe className="w-full h-500" height="500"  src="https://www.youtube.com/embed/fhp-Xp9Iibk?si=QLOGlYuTI78HGCd5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div></div>

            {/* <div className="flex flex-col justify-center items-center space-y-10 w-full md:w-1/2 md:pl-15">
        <p className="comic-sans-large text-yellow-100 text-center">
            You can start <br /> a business at <br /> a younger <br /> age!
        </p>
        <button
            className="mt-8 btn outline-button text-white py-1 px-6 border rounded-2xl"
            // onClick={handleStartCourse}
            >
            Learn More
          </button>
    </div> */}


            {/* <div className="flex justify-center items-center w-full">
                <video controls className=" h-[85vh]">
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div> */}
        </>
    );
};

export default FilagotVideo;