import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";

import logoImage from "../assets/images/logoAndText.png";
import textLogo from "../assets/images/text_logo_transparent_720.png";

import { auth, db } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { query, collection, getDocs, where } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

import Modal from "./Modal";
import { useUserAuth } from "./Auth/UserAuthContext";
import { useDispatch, useSelector } from "react-redux";
import {
  setSignInOpen,
  setSignUpOpen,
  setCloseAll,
} from "../redux/slices/authDialogSlice";
import { clearUser } from "../redux/slices/userSlice";
import { clearPathName } from "../redux/slices/locationSlice";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const TOP_OFFSET = 50;
  // const userFirebase = useSelector((state) => state.userData.userFirebase);

  const { t } = useTranslation();

  const [nav, setNav] = useState(false);
  const [showBackground, setShowBackground] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const firebaseUser = useSelector((state) => state.userData.user);
  const [user, loading, error] = useAuthState(auth);
  const [users, setUsers] = useState(user);

  const [name, setName] = useState("");
  const [uid, setUID] = useState("");
  const navigate = useNavigate();
  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.firstname);
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    fetchUserName();
    const handleScroll = () => {
      // console.log(window.scrollY);
      if (window.scrollY >= TOP_OFFSET) {
        setShowBackground(true);
      } else {
        setShowBackground(false);
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUID(user.uid);
        console.log("uid", uid);
      } else {
        console.log("user is logged out");
      }
    });

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setUsers(user);
  }, [user]);

  const handleNavClick = () => {
    setNav(!nav);
  };

  const handleSignUp = () => {
    dispatch(setSignUpOpen());
    // setShowRegistrationModal(true);
  };

  const handleSignIn = () => {
    console.log("sign in is clicked");
    dispatch(setSignInOpen());
    // setShowRegistrationModal(true);
  };

  const handleLogOut = () => {
    auth.signOut();

    dispatch(setCloseAll());
    dispatch(clearUser());
    dispatch(clearPathName());
    navigate("/");
    // setShowRegistrationModal(true);
  };

  const handleModal = (values) => {
    setShowRegistrationModal(false);
    // console.log("input values are", values);
  };
  return (
    <div className="flex justify-around w-full absolute sm:w-full min-h-[70px] flex justify-between items-center absolute z-10 text-white mb-10 fixed-navbar p-5">     
     <div className="container mx-auto flex justify-between items-center">     
        <div className="flex items-center">
          <NavLink to="/">
            <img
              src={logoImage}
              alt="Logo"
              className="h-190 w-190 sm:h-[190px] w-[190px] rounded-full mr-4"
            />
          </NavLink>
          <ul className="hidden md:flex space-x-8">
            <div className="bg-[#F0CF2D] opacity-100 rounded-full">
              <li>
                <NavLink to="/" className="bubblegum-sans-link text-black">
                  {t('home')}
                </NavLink>
              </li>
            </div>
            <div className="bg-[#F0CF2D] rounded-full">
              <li>
                <NavLink to="/blogs" className="bubblegum-sans-link text-black">
                  {t('blog')}
                </NavLink>
              </li>
            </div>
            <div className="bg-[#F0CF2D] rounded-full">
              <li>
                <NavLink to="/books" className="bubblegum-sans-link text-black">
                  Our Books
                </NavLink>
              </li>
            </div>
            <div className="bg-[#F0CF2D] rounded-full">
              <li>
                <NavLink to="/courses" className="bubblegum-sans-link text-black">
                  {t('courses')}
                </NavLink>
              </li>
            </div>
            <div className="bg-[#F0CF2D] rounded-full">
              <li>
                <NavLink to="/programs" className="bubblegum-sans-link text-black">
                  {t('events')}
                </NavLink>
              </li>
            </div>
          </ul>
        </div>
        <div className="flex items-center space-x-4">
          {user && firebaseUser ? (
            <button
              onClick={handleLogOut}
              className="btn outline-button border rounded-2xl text-white"
            >
              {t('logOut')}
            </button>
          ) : (
            <>
              <button
                onClick={handleSignIn}
                className="btn outline-button border rounded-2xl text-white"
              >
                {t('signIn')}
              </button>
              <button
                onClick={handleSignUp}
                className="btn outline-button border rounded-2xl text-white"
              >
                {t('signUp')}
              </button>
            </>
          )}
          <div className="md:hidden">
            <FaBars
              size={20}
              className="text-black cursor-pointer"
              onClick={handleNavClick}
            />
          </div>
        </div>
      </div>
      <div
        onClick={handleNavClick}
        className={
          nav
            ? "overflow-y-hidden md:hidden ease-in duration-300 absolute right-0 w-1/2 top-0 h-auto bg-[#F0CF2D] text-black px-4 py-7 flex flex-col"
            : "absolute top-0 h-screen left-[-100%] ease-in duration-500 "
        }
      >
        <FaBars
          size={20}
          className="text-black cursor-pointer flex justify-right"
          onClick={handleNavClick}
        />
        <ul className="h-full w-full text-center pt-12">
          <li className="text-2xl py-8">
            <a href="/">Home</a>
          </li>
          <li className="text-2xl py-8">
            <NavLink to="/blogs">Blog</NavLink>
          </li>
          <li className="text-2xl py-8">
            <NavLink to="/books">Our Books</NavLink>
          </li>
          <li className="text-2xl py-8">
            <NavLink to="/courses">Courses</NavLink>
          </li>
          <li className="text-2xl py-8">
            <NavLink to="/programs">Events</NavLink>
          </li>
        </ul>
      </div>
    </div>

  );
};

export default Navbar;
