import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { query, collection, getDocs, where } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../redux/slices/userSlice";
import book from "../assets/images/cover.png";
import PurchaseButton from "./Payment/PurchaseButton";

const ChapterSummaryCard = () => {

  const dispatch = useDispatch();
  const { bookId } = useParams();
  const [user, loading, error] = useAuthState(auth);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      console.log("DOCS", doc);
      const data = doc.docs[0].data();
      console.log("DATA", data);
      dispatch(setUser(data));
      setFirstName(data.firstname);
      setLastName(data.lastname);
      setPhoneNumber(data.phoneNumber);
      setEmail(data.email);
      console.log("NAME:", data.firstname);
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };

  const handlePaymentClick = async () => {
    try {
      console.log("id from front-end", bookId);
      const response = await fetch('http://localhost:9000/chapaAPI/pay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          first_name: first_name,
          last_name: last_name,
          phone_number: phone_number,
          bookId: bookId
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      window.location.href = data.checkout_url;
      // console.log("THE DATA!", data);
    }
    catch (error) {
      console.error('Error:', error);
    }
   
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // onAuthStateChanged(auth, (user) => {
    //   if (user) {
    //     setUID(user.uid);
    //     console.log("uid", uid);
    //   } else {
    //     console.log("user is logged out");
    //   }
    // });
    // fetchUserName();
  }, [user]);

  return (

    <Card className="p-4 bg-regal-blue text-white">
      <CardBody>
        <Typography variant="h4" className="comic-sans-medium mb-2 text-yellow-100">
          Business For Kids
        </Typography>
        <Typography className="text-yellow-100">
          Discover a world of endless possibilities with our engaging and educational  <br />business ebook for kids!        </Typography>
        <Typography variant="h2" className="text-yellow-100 comic-sans-small border-t-2 pt-2 mt-2 text-xl">
          Beginner level
        </Typography>
        <Typography className="text-yellow-100">Recommended experience</Typography>
        <Typography variant="h2" className="comic-sans-small text-yellow-100 border-t-2 pt-2 mt-2 text-xl">
          Price: 300 ETB
        </Typography>
        <Typography variant="h2" className="comic-sans-small text-yellow-100 border-t-2 pt-2 mt-2 text-xl">
          Flexible schedule
        </Typography>
        <Typography className="text-yellow-100">Learn at your own pace</Typography>
      </CardBody>
      <CardFooter className="pt-4">
        <button
          onClick={handlePaymentClick}
          className="bg-button text-black font-semibold hover:text-black py-2 px-2  hover:border-black rounded "
        >
          Purchase Course
        </button>
        {/* <PurchaseButton /> */}
      </CardFooter>
    </Card>


  );
};

export default ChapterSummaryCard;
