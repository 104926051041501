import React from "react";
import smiling from "../assets/images/confidence.png";
import love from "../assets/images/learning.png";
import challenge from "../assets/images/challenges.png";

function Description() {
  return (


    <div className=" py-2">
      <h2 className="comic-sans-medium text-center md:text-5xl text-yellow-100 whitespace-pre-line md:leading-relaxed m-10">
        What happens when kids use our resources?
      </h2>
      <div className="flex flex-wrap justify-center gap-20 h-auto px-2">
        <div className="bg-yellow-100 rounded-lg shadow-md p-1 max-w-xs flex-1 space-y-8">
          <img src={smiling} alt="Resource 1" className="w-full h-auto rounded-lg mb-4" />
          <h3 className="text-xl text-black text-center comic-sans-medium font-semibold mb-2">Increases Confidence</h3>
          <p className="comic-sans-small text-center">Entrepreneurship learning boosts kids' confidence by nurturing their creativity and belief in their innovative abilities.</p>
        </div>
        <div className="bg-yellow-100 rounded-lg shadow-md p-6 max-w-xs flex-1 space-y-8">
          <img src={love} alt="Resource 2" className="w-full h-auto rounded-lg mb-4" />
          <h3 className="text-xl text-black text-center comic-sans-medium font-semibold mb-2">Love of Learning</h3>
          <p className="text-black comic-sans-small text-center">Entrepreneurship training enhances a child's love for learning by connecting academics to real-world applications through hands-on activities.</p>
        </div>
        <div className="bg-yellow-100 rounded-lg shadow-md p-2 max-w-xs flex-1 space-y-8">
          <img src={challenge} alt="Resource 3" className="w-full h-auto rounded-lg mb-4" />
          <h3 className="text-xl text-black text-center comic-sans-medium font-semibold mb-4">Encourages New Challenges</h3>
          <p className="text-black comic-sans-small text-center mt-5">Entrepreneurship learning encourages kids to embrace new challenges and develop problem-solving, risk-taking, and decision-making skills in a collaborative environment.</p>
        </div>
      </div>
    </div>

    // text-lg md:text-xl  
    // <div className="flex flex-col">
    //   <h2 className="comic-sans-medium md:text-5xl my-5 text-yellow-100 text-center dark:text-white leading-tight md:leading-normal">
    //     What happens when kids use our resources?
    //   </h2>
    //   <div className="grid grid-cols-1 space-y-10 md:space-y-0 sm:grid-cols-2 md:grid-cols-3 gap-3 p-10 mx-10">
    //   <div className="max-w-xs mx-auto flex flex-col justify-center items-center bg-yellow-100 p-5 rounded-lg">
    //           <a href="#">
    //             <img
    //               src={smiling}
    //               className="mb-5 rounded-lg w-full"
    //               width={120} height={120}
    //               alt="Image 1"
    //             />
    //           </a>
    //           <h2 className="mb-2 comic-sans-medium text-xl text-center font-bold leading-tight text-black dark:text-white">
    //             <p>Increases Confidence</p>
    //             {/* <p className="roboto-tiny">Our entrepreneurship learning is a powerful tool for boosting confidence in kids. At this critical stage of development, children are exploring their identities, abilities, and place in the world. Learning about entrepreneurship through our platform helps them build confidence in several key ways. Entrepreneurship learning nurtures creativity by encouraging kids to think outside the box. When children are given the freedom to come up with their own ideas and solutions, they start to believe in their creative abilities. This belief in their capacity to innovate and create builds self-confidence.</p> */}
    //           </h2>
    //     </div>
    //     <div className="max-w-xs mx-auto flex flex-col justify-center items-center bg-yellow-100 p-5 rounded-lg">
    //           <a href="#">
    //             <img
    //               src={love}
    //               className="mb-5 rounded-lg w-full"
    //               width={130} height={130}
    //               alt="Image 1"
    //             />
    //           </a>
    //           <h2 className="mb-2 comic-sans-medium text-xl text-center font-bold leading-tight text-black dark:text-white">
    //             <p>Love of Learning</p>
    //           </h2>
    //     </div>
    //     <div className="max-w-xs mx-auto flex flex-col justify-center items-center bg-yellow-100 p-5 rounded-lg">
    //           <a href="#">
    //             <img
    //               src={challenge}
    //               className="mb-5 rounded-lg w-full"
    //               width={130} height={130}
    //               alt="Image 1"
    //             />
    //           </a>
    //           <h2 className="mb-2 comic-sans-medium text-xl text-center font-bold leading-tight text-black dark:text-white">
    //             <p>Encourages New Challenges</p>
    //           </h2>
    //     </div>
  );
}

export default Description;
