import React from "react";
import know from "../assets/images/know.jpg";
import video from "../assets/videos/helpingVideo.mp4";
import { useNavigate } from "react-router-dom";

const Know = () => {
    return (
        <div>
            <h2 className="comic-sans-medium text-center md:text-5xl bg-yellow-100 text-gray-700 rounded-lg whitespace-pre-line md:leading-relaxed m-8">
        Get to know us!
      </h2>
      <div className="flex flex-col md:flex-row justify-between items-center p-8">
      <p className="comic-sans-small text-yellow-100 text-center w-full h-auto md:w-[60%] md:h-auto bg-gray-700 p-3 rounded-3xl"> Welcome to Mentorable, a platform designed for the mentor-able young generation. <br /> At Mentorable, we provide training for kids in business and entrepreneurship, financial management, <br />social education, and sustainability. <br />Our goal is to break down trending topics that are typically geared towards adults into kid friendly content. <br />Through our platform, kids and teens can learn online and connect with their peers globally and make themselves relevant in this fast changing world. <br />Join us at Mentorable as we empower the next generation with valuable skills and knowledge for a successful future.</p>
      <img
          className="w-full md:w-[40%] h-400 mt-8 md:m-5 border-2 border-black shadow-lg cursor-pointer rounded-lg "
          src={know}
        />
        </div>
        </div>
    );
}

export default Know;